import { defineStore } from 'pinia';

export const usePwa = defineStore('pwa', {
    state: () => {
        return {
            deferredPrompt: null,
            installed: true,
        }
    },
    getters: {
        isInstalled: (state) => state.installed,
    },
    actions: {
        init() {
            window.addEventListener('beforeinstallprompt', (e) => {
                this.deferredPrompt = e;
                this.installed = false; //Si aun no se ha instalado la pwa coloca la variable en false
            });
        },
        async istall() {
            if (this.isInstalled || this.deferredPrompt === null) return;

            this.deferredPrompt.prompt();
            const { outcome } = await this.deferredPrompt.userChoice;

            if (outcome === 'accepted') {
                this.deferredPrompt = null;
            }
        },
    },
})