import { defineStore } from 'pinia';
import { products_arg } from '@/static/products-arg';
import { products_uru } from '@/static/products-uru';
import { ARG } from '@/static/country-code';

export const useShop = defineStore('shop', {
    state: () => {
        return {
            country: ARG,
        }
    },
    getters: {
        phone: (state) => state.country == ARG ? process.env.VUE_APP_ARG_PHONE : process.env.VUE_APP_URU_PHONE,
        catalogue: (state) => state.country == ARG ? products_arg : products_uru,
        burgers: (state) => state.catalogue.filter(x => x.category == 1),
        dressings: (state) => state.catalogue.filter(x => x.category == 2),
        others: (state) => state.catalogue.filter(x => x.category == 3),
        brandImage: (state) => state.country == ARG ? '/logo.png' : '/aduki.jpg',
        countryImage: (state) => state.country == ARG ? '/country/arg.png' : '/country/uru.png',
        hasCountrySelected: () => {
            const currentCountry = localStorage.getItem('hasSelectedCountry');
            return !!currentCountry;
        },
    },
    actions: {
        async init() {
            try {
                let currentCountry = localStorage.getItem('selectedCountry');
                if (!currentCountry) {
                    const response = await fetch(`https://ipinfo.io/json?token=${process.env.VUE_APP_IPINFO_TOKEN}`);
                    const jsonResponse = await response.json();
                    localStorage.setItem('selectedCountry', jsonResponse.country);
                }
                currentCountry = localStorage.getItem('selectedCountry');
                this.country = currentCountry;
            } catch {
                this.country = ARG;
                localStorage.setItem('selectedCountry', ARG);
            }
        },
        setCountry(country) {
            this.country = country;
            localStorage.setItem('selectedCountry', country);
            localStorage.setItem('hasSelectedCountry', true);
        },
        countryName(alias) {
            return alias
        },
        sendMessage(message = '') {
            const wp = window.open(`https://wa.me/${this.phone}?text=${message}`);
            setTimeout(() => wp.close(), 2000);
        },
    },
})