import { defineStore } from 'pinia';
import { useShop } from '@/store/shop';
import { resolveCategoryName } from '@/helpers/category-name-resolver';

export const useOrder = defineStore('order', {
    state: () => {
        return {
            products: [],
        }
    },
    getters: {
        price: (state) => {
            let price = 0;
            const SHOP = useShop();

            state.products.forEach(id => {
                const product = SHOP.catalogue.find(x => x.id == id);
                price += product.price;
            });

            return price;
        },
        detail: (state) => {
            let lines = [];
            const SHOP = useShop();
            const productSetIds = new Set(state.products);

            productSetIds.forEach(id => {
                const count = state.products.filter(x => x == id).length;
                const product = SHOP.catalogue.find(x => x.id == id);
                let category = resolveCategoryName(product.category);
                category = count > 1 ?`${category}s`:category;

                lines.push({
                    id: product.id,
                    name: product.name.toUpperCase(),
                    price: product.price,
                    category: category,
                    count: count,
                });
            });

            return lines;
        },
    },
    actions: {
        addProduct(id) {
            this.products.push(id);
        },
        deleteProduct(id) {
            let index = this.products.indexOf(id);
            if (index < 0 ) return;

            this.products.splice(index, 1);
        },
        getProductCount(id) {
            return this.products.filter(x => x == id).length;
        },
        confirm() {
            const SHOP = useShop();
            let message = 'Hola,%20te%20quería%20pedir%0A%0A';
            
            const detail = this.detail;
            const bodyTextDetail = detail.reduce(
                (acum, product) => 
                `${product.count}%20${!product.category ? '' : `${product.category}%20de`}%20${product.name}%0A${acum}`,
                ''
            );
            message += `${bodyTextDetail}`;

            message += `%0A%0ATOTAL: $${this.price}`;
            SHOP.sendMessage(message);
            this.reset();
        },
        reset() {
            this.products = [];
        },
    },
})