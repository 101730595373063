const burgers = [
    { id:'arg-burger-1', category: 1, img: '/burger/acelga.jpg', name: 'acelga', description: 'Acelga, garbanzos, avena, cebolla rehogada, zanahoria y chía. Condimentos: sal, pimentón, albahaca, ajo, perejil y orégano.', price: 760 },
    { id:'arg-burger-2', category: 1, img: '/burger/arveja.jpg', name: 'arveja', description: 'Arveja, arroz integral, avena, cebolla rehogada, zanahoria y chía. Condimentos: sal, pimentón, albahaca, ajo, perejil, orégano y limón.', price: 760 },
    { id:'arg-burger-3', category: 1, img: '/burger/garbanzo.jpg', name: 'garbanzo', description: 'Garbanzo, arroz integral, avena, cebolla rehogada, zanahoria y chía. Condimentos: sal, pimentón, albahaca, ajo, perejil, orégano, limón y cúrcuma.', price: 760 },
    { id:'arg-burger-4', category: 1, img: '/burger/legumbres-al-curry.jpg', name: 'curry', description: 'Poroto blanco, proto negro, arveja, lenteja, garbanzo, arroz integral, avena, cebolla rehogada, zanahoria y chía. Condimentos: curry, sal, pimentón, albahaca, ajo, perejil, orégano y limón.', price: 760 },
    { id:'arg-burger-5', category: 1, img: '/burger/lenteja.jpg', name: 'lenteja', description: 'Lenteja, arroz integral, avena, cebolla rehogada, zanahoria y chía. Condimentos: sal, pimentón, albahaca, ajo, perejil, orégano y limón.', price: 760 },
    { id:'arg-burger-6', category: 1, img: '/burger/poroto.jpg', name: 'poroto', description: 'Poroto blanco, arroz integral, avena, cebolla rehogada, zanahoria y chía. Condimentos: sal, pimentón, albahaca, ajo, perejil, orégano y limón.', price: 760 },
    { id:'arg-burger-7', category: 1, img: '/burger/remolacha.jpg', name: 'remolacha', description: 'Remolacha, arroz integral, avena, cebolla caramelizada, zanahoria y chía. Condimentos: sal.', price: 760 },
    { id:'arg-burger-8', category: 1, img: '/burger/zapallo.jpg', name: 'zapallo', description: 'Zapallo, garbanzo, avena, cebolla caramelizada, zanahoria y chía. Condimentos: sal, ajo, pimentón y perejil.', price: 760 },
    { id:'arg-burger-9', category: 1, img: '/burger/quinoa-y-puerro.jpg', name: 'quinoa y puerro', description: 'Quinoa, puerro, avena, arroz integral, cebolla rehogada, zanahoria y chía. Condimentos: sal, ajo, pimentón. perejil y limón.', price: 760 },
    { id:'arg-burger-10', category: 1, img: '/burger/lenteja.jpg', name: 'lenteja y tomate seco', description: 'Lentejas, tomates secos, avena, arroz integral, cebolla rehogada, zanahoria y chía. Condimentos: sal, pimentón, albahaca, ajo y perejil.', price: 790 },
];

const dressings = [
    { id:'arg-dressings-1', category: 2, img: '/dressing/garbanzo.jpeg', name: 'garbanzo', description: 'Garbanzo, aceite de girasol. Condimentos: sal, limón, ajo, perejil y pimentón.', price: 2200 },
    { id:'arg-dressings-2', category: 2, img: '/dressing/lenteja.jpeg', name: 'lenteja', description: 'Lenteja, aceite de girasol. Condimentos: sal, limón, ajo, perejil y pimentón.', price: 2200 },
    { id:'arg-dressings-3', category: 2, img: '/dressing/zanahoria.jpeg', name: 'zanahoria', description: 'Zanahoria, aceite de girasol. Condimentos: sal, limón, ajo y perejil.', price: 2200 },
    { id:'arg-dressings-4', category: 2, img: '/dressing/remolacha.jpeg', name: 'remolacha', description: 'Remolacha, aceite de girasol. Condimentos: sal, limón, ajo y perejil.', price: 2200 },
];

const others = [
    { id:'arg-others-1', category: 3, img: '/other/castanias-caju.jpeg', name: 'Castañas de Cajú', description: 'Las castañas de cajú son una excelente fuente de grasas saludables, proteínas, fibra, vitaminas y minerales. Son ricas en ácidos grasos insaturados, como el ácido oleico y el ácido linoleico, que son beneficiosos para la salud del corazón. También contienen magnesio, fósforo, hierro y zinc, que son importantes para el funcionamiento adecuado del cuerpo. Además, las castañas de cajú son una buena fuente de antioxidantes, que ayudan a combatir el daño causado por los radicales libres en el cuerpo. ¡Son un snack delicioso y nutritivo!', price: 1250, unity: 'c/100 gr' },
    { id:'arg-others-2', category: 3, img: '/other/pan-salvado.jpeg', name: 'Pan de Salvado', description: 'Pan de salvado (masa madre) “La Francia”, 600 Gr.', price: 2800, unity: 'c/u' },
    { id:'arg-others-3', category: 3, img: '/other/guiso1.jpeg', name: 'Guiso de Lentejas', description: 'Lentejas, arroz integral, zanahorio, zapallo, papa, salsa de tomate y cebolla. Condimentos: sal, ajo, orégano, perejil, pimentón y albahaca. Vegano y congelado.', price: 3300, unity: 'c/u' },
];

export const products_arg = [
    ...burgers,
    ...dressings,
    ...others
];