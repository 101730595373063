export const resolveCategoryName = (categoryId) => {
    let categoryText;
    switch (categoryId) {
        case 1:
            categoryText = 'Hamburguesa';
        break;
        case 2:
            categoryText = 'Aderezo';
        break;
        default:
            categoryText = '';
        break;
    }
    
    return categoryText;
};