<template>
    <v-card title="SELECCIONA TU PAIS">
        <div class="country-options__container">
            <div @click="model = ARG">
                <v-img
                    height="100px"
                    src="/country/arg.png"
                    :class="{ 'country-non-selected': model != ARG }"
                >
                </v-img>
            </div>
            <div @click="model = URU">
                <v-img
                    height="100px"
                    src="/country/uru.png"
                    :class="{ 'country-non-selected': model != URU }"
                >
                </v-img>
            </div>
        </div>
        <v-card-text>
            <small>Tu opción seleccionada es: <b>{{ currentCountry }}</b></small>
            <br>
            <br>
            <small>Luego podrás cambiarlo desde la parte superior derecha.</small>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="error"
                variant="outlined"
                @click="emit('cancel')"
            >
                Cancelar
            </v-btn>
            <v-btn
                color="success"
                variant="outlined"
                @click="confirmCountry"
            >
                Confirmar
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script setup>
import { ARG, URU } from '@/static/country-code';
import {
    ref,
    defineProps,
    defineEmits,
    watchEffect,
    computed,
} from 'vue';

const props = defineProps({
    current: {
        type: String,
        required: true
    },
});

const emit = defineEmits(['confirm', 'cancel']);
const model = ref('');

const currentCountry = computed(() => {
  return model.value == ARG ? 'ARGENTINA' : 'URUGUAY';
})

const confirmCountry = () => {
    emit('confirm', model)
};

watchEffect(() => {
    model.value = props.current;
});

</script>

<style scoped>
.country-options__container {
    display: flex;
    justify-content: space-around;
}

.country-non-selected {
    filter: grayscale(100%);
}
</style>
