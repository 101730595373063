<template>
  <v-app>
    <v-app-bar>
      <img
        alt="Logo"
        class="img-responsive px-3"
        :src="SHOP.brandImage"
        style="height: 45px;"
      >
      <v-btn
        v-if="!PWA.isInstalled"
        @click="PWA.istall"
      >
        Instalar APP
      </v-btn>
      <v-spacer></v-spacer>
      <span
        v-if="ORDER.price"
        class="d-flex justify-center align-center px-3"
        @click="toggleOrderDetail"
      >
        <v-icon
          icon="mdi-cart-outline"
          class="px-2"
        >
        </v-icon>
        <b>${{ ORDER.price }}</b>
        <v-icon
          :icon="`mdi-menu-${showOrderDetail ? 'up' : 'down'}`"
          class="px-2"
        >
        </v-icon>
      </span>
      <img
        alt="Country Image"
        class="img-responsive px-3"
        :src="SHOP.countryImage"
        style="height: 45px;"
        @click="showCountrySelection = true"
      >
    </v-app-bar>
    <v-container class="main__container">
      <div v-if="SHOP.burgers.length">
        <h3 class="category-title">MEDALLONES CONGELADOS</h3>
        <v-row>
          <v-col
            v-for="burger in SHOP.burgers"
            :key="burger.img"
            cols="12"
            sm="6"
            md="4"
            xl="2"
          >
            <BurgerCard
              :id="burger.id"
              :name="burger.name"
              :image="burger.img"
              :price="burger.price"
              :order-count="ORDER.getProductCount(burger.id)"
              @show-detail="setProductDetail(burger)"
              @add="ORDER.addProduct(burger.id)"
              @delete="ORDER.deleteProduct(burger.id)"
            />
          </v-col>
        </v-row>
      </div>
      <div v-if="SHOP.dressings.length">
        <h3 class="category-title">ADEREZOS VEGANOS</h3>
        <v-row>
          <v-col
            v-for="dressing in SHOP.dressings"
            :key="dressing.id"
            cols="12"
            sm="6"
            xl="3"
          >
            <DressingCard
              :id="dressing.id"
              :name="dressing.name"
              :image="dressing.img"
              :price="dressing.price"
              :order-count="ORDER.getProductCount(dressing.id)"
              @show-detail="setProductDetail(dressing)"
              @add="ORDER.addProduct(dressing.id)"
              @delete="ORDER.deleteProduct(dressing.id)"
            />
          </v-col>
        </v-row>
      </div>
      <div v-if="SHOP.others.length">
        <h3 class="category-title">OTROS</h3>
        <v-row>
          <v-col
            v-for="other in SHOP.others"
            :key="other.id"
            cols="12"
            sm="6"
            xl="3"
          >
            <OtherCard
              :id="other.id"
              :name="other.name"
              :image="other.img"
              :price="other.price"
              :unity="other.unity"
              :order-count="ORDER.getProductCount(other.id)"
              @show-detail="setProductDetail(other)"
              @add="ORDER.addProduct(other.id)"
              @delete="ORDER.deleteProduct(other.id)"
            />
          </v-col>
        </v-row>
      </div>
    </v-container>
    <v-bottom-navigation v-if="!showProductDetail" class="w-100">
      <div v-if="ORDER.price" class="w-100">
        <v-btn @click="showCancelQuestion = true" class="pb-3 w-50">
          <v-icon icon="mdi-cart-off"></v-icon>
          Cancelar
        </v-btn>
        <v-btn @click="ORDER.confirm()" class="pb-3 w-50">
          <v-icon icon="mdi-cart-arrow-right"></v-icon>
          Confirmar
        </v-btn>
      </div>
      <div v-else class="w-100">
        <v-btn
          @click="SHOP.sendMessage()"
          style="width: 100%;"
        >
          <div class="action-contact__container">
            <v-icon icon="mdi-whatsapp" />
            Contactarme
          </div>
        </v-btn>
      </div>
    </v-bottom-navigation>
    <v-navigation-drawer
      v-model="showProductDetail"
      location="bottom"
      temporary
      style="height: auto;"
      touchless
    >
      <v-card
        :title="productDetail.title"
        :subtitle="`$${productDetail.price} c/u`"
        :text="productDetail.description"
      >
      </v-card>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="showOrderDetail"
      location="bottom"
      temporary
      style="max-height: 60vh; height: auto;"
      touchless
    >
      <v-card
        title="Detalle del pedido"
        :subtitle="`Costo total $${ORDER.price}`"
      >
        <v-card-text>
          <v-list
            v-for="product in ORDER.detail"
            :key="product.id"
          >
            <div class="d-flex justify-space-between align-center">
              <div class="d-flex align-center">
                <v-btn
                  icon="mdi-minus"
                  size="x-small"
                  @click="ORDER.deleteProduct(product.id)"
                >
                </v-btn>
                <span class="ml-2">
                  <small>
                    <b>{{ ORDER.getProductCount(product.id) }}</b>
                    {{ `${!product.category ? '' : `${product.category} de`} ${product.name}` }}
                  </small>
                  <p><small><i>{{ `Sub total: $${product.count * product.price}` }}</i></small></p>
                </span>
              </div>
              <v-btn
                icon="mdi-plus"
                size="x-small"
                @click="ORDER.addProduct(product.id)"
              >
              </v-btn>
            </div>
          </v-list>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-dialog
      v-model="showCancelQuestion"
      persistent
      width="auto"
    >
      <v-card
        title="Cancelar Pedido"
        text="Se eliminarán todos los productos seleccionados, estas seguro que deseas cancelarlo?"
      >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            variant="outlined"
            @click="showCancelQuestion = false"
          >
            NO
          </v-btn>
          <v-btn
            color="success"
            variant="outlined"
            @click="cancelOrder"
          >
            SI
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showCountrySelection"
      persistent
      width="auto"
    >
      <CountrySelector
        :current="SHOP.country"
        @cancel="showCountrySelection = false"
        @confirm="setCountry"
      />
    </v-dialog>
  </v-app>
</template>

<script setup>
import { onMounted } from 'vue';
import { useOrder } from '@/store/order';
import { useShop } from '@/store/shop';
import { usePwa } from '@/store/pwa';
import { ref, watchEffect } from 'vue';

import BurgerCard from '@/components/BurgerCard';
import DressingCard from '@/components/DressingCard';
import OtherCard from '@/components/OtherCard';
import CountrySelector from '@/components/CountrySelector.vue';

import { resolveCategoryName } from '@/helpers/category-name-resolver';

const ORDER = useOrder();
const SHOP = useShop();
const PWA = usePwa();

const showProductDetail = ref(false);
const showOrderDetail = ref(false);
const showCancelQuestion = ref(false);
const showCountrySelection = ref(false);

const productDetail = ref({
  title: '',
  price: '',
  description: '',
});

const setProductDetail = (product) => {
  const categoryName = resolveCategoryName(product.category);
  productDetail.value.title = `${!categoryName ? '' : `${categoryName} de` } ${product.name}`;
  productDetail.value.price = product.price;
  productDetail.value.description = product.description;
  showProductDetail.value = !showProductDetail.value;

  showOrderDetail.value = false;
};

const toggleOrderDetail = () => {
  showOrderDetail.value = !showOrderDetail.value;

  if (!showOrderDetail.value) return;

  showProductDetail.value = false;
};

const cancelOrder = () => {
  ORDER.reset();
  showCancelQuestion.value = false;
};

const setCountry = (data) => {
  showCountrySelection.value = false;
  ORDER.reset();
  SHOP.setCountry(data.value);
}

watchEffect(() => {
  if (ORDER.price) return;

  showOrderDetail.value = false;
});

onMounted(async () => {
  PWA.init();
  await SHOP.init();

  showCountrySelection.value = !SHOP.hasCountrySelected;
});
</script>

<style scoped>

.main__container {
  margin: 60px auto;
}

.category-title {
  margin: 15px 0;
}

.action-contact__container {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  color: green;
}
</style>
